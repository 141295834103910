import axios from "axios";

const instance = axios.create({
  baseURL: 'http://44.228.139.42:5001',
});

const fetchCreds = () => {
  instance.interceptors.request.use(function (config) {
    const token = sessionStorage?.token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });
};

export const getRequest = async ({url, params}) => {
  fetchCreds();
  const { data } = await instance.get(url, { params: params });
  return data;
};

export const postRequest = async ({url, payload}) => {
  fetchCreds();
  const { data } = await instance.post(url, { ...payload });
  return data;
};

export const postRequestUploadFile = async ({url, payload}) => {
  fetchCreds();
  const { data } = await instance.post(url, payload.formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const patchRequest = async ({url, payload}) => {
  fetchCreds();
  const { data } = await instance.patch(url, { ...payload });
  return data;
};