import Header from "../../components/header/header";
import HomeTiles from "../../components/home-tiles/homeTiles";

import './home.scss';

export default function Home() {
    return (
        <div className="home-wrapper">
            <Header />
            <div className="home-grid-section">
                <HomeTiles />
            </div>
        </div>
    );
}