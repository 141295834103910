import { useEffect, useState } from 'react';
import './dialog.scss';

export default function Dialog(props) {
    const modalType = props.modalType ? props.modalType : '';
    const [showModal, setShowModal] = useState(props.showDialog);

    const dismissDialog = () => {
        setShowModal('none');
        props.dismissDialog();
    };

    useEffect(() => {
        setShowModal(props.showDialog);
    }, [props]);

    return (
        <div className={`modal ${modalType}`} style={{ display: showModal }}>
            <div className="modal-content">
                {/* <span className="close-fix" onClick={() => dismisDialog()}>&times;</span>*/ }
                <div className="modal-header">
                    <div className="close-container" onClick={() => dismissDialog()}>
                        <span className="close-text">Close</span>
                        <span className="close">&times;</span>
                    </div>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                {/* <div className="modal-footer">
                    <h3>Modal Footer</h3>
                </div> */}
            </div>
        </div>
    );
}