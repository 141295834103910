import logo from '../../assets/images/pycube-logo.svg';

import './header.scss';

export default function Header() {
    return (
        <header>
            <div>
                <img src={logo} alt="pycube marketing" width={200} />
            </div>
            <div>
                <p>Process Digitization and Automation for Healthcare</p>
            </div>
        </header>
    );
}