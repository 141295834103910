
import { useRef, useState } from 'react';
import Dialog from '../dialog/dialog';
import './home-tiles.scss';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

// import pdfFile from '../../assets/tiles/avia-conference-ai-use-cases.pdf';

export default function HomeTiles() {

    const videoTag = useRef(null);

    const dialogConfigData = {
        modalType: 'full-modal',
        showDialog: 'none',
        header: true,
        footer: true
    };

    const [dialogConfig, setDialogConfig] = useState({ ...dialogConfigData });
    const [pdfFile, setPdfFile] = useState(null);
    const [dialogType, setDialogType] = useState('pdf');
    const [videoUrl, setVideoUrl] = useState(null);

    const data = [
        [
            {
                title: 'Confronting Healthcare Challenges Video',
                classes: 'item mb-20 w-100 h-300 blue-color-03',
                url: 'https://www.youtube.com/embed/OBob_UtfykI',
                type: 'video'
            },
            {
                title: 'Digital Asset AI Solution Video',
                classes: 'item mb-20 w-100 h-150 blue-color-04',
                url: 'https://www.youtube.com/embed/nDQ8aYeLliw',
                type: 'video'
            },
            {
                title: 'Pycube Deck',
                classes: 'item mb-20 w-100 h-150 blue-color-05',
                url: 'pycube-overview-deck.pdf',
                type: 'pdf'
            }
        ],
        [
            {
                rowData: [
                    {
                        title: 'GUDID Video',
                        classes: 'item mr-20 mb-20 w-50 h-150 blue-color-06',
                        url: 'https://www.youtube.com/embed/rn19iv2vkGo',
                        type: 'video'
                    },
                    {
                        title: 'Digital Asset Protection Video',
                        classes: 'item mr-0 mb-20 w-50 h-150 blue-color-02',
                        url: 'https://www.youtube.com/embed/_n84lFI_hVg',
                        type: 'video'
                    },
                ],
                classes: 'row-flex'
            },
            {
                title: 'Digital Specimen challenegs Video',
                classes: 'item mb-20 w-100 h-300 blue-color-07',
                url: 'https://www.youtube.com/embed/kzLr7iNbeko',
                type: 'video'
            },
            {
                rowData: [
                    {
                        title: 'AI Deck',
                        classes: 'item mr-0 mb-20 w-50 h-150 blue-color-08',
                       url: 'avia-conference-ai-use-cases.pdf',
                       type: 'pdf'
                    }
                ],
                classes: 'row-flex'
            }
        ],
        [
            {
                rowData: [
                    {
                        title: 'SAP Desktop Walkthrough Video',
                        classes: 'item mr-20 mb-20 w-50 h-150 blue-color-06',
                        url: 'https://www.youtube.com/embed/ySVK6xIjrGQ',
                        type: 'video'
                    },
                    {
                        title: 'Automated Invoice AI Video',
                        classes: 'item mr-0 mb-20 w-50 h-150 blue-color-02',
                        url: 'https://www.youtube.com/embed/Vy46rkHD2Po',
                        type: 'video'
                    },
                ],
                classes: 'row-flex'
            },
            {
                title: 'Knowledge Base Video',
                classes: 'item mb-20 w-100 h-300 blue-color-07',
                url: 'https://www.youtube.com/embed/GkZjtVSv-6A',
                type: 'video'
            },
            {
                rowData: [
                    {
                        title: 'Digital Sample Solution Video',
                        classes: 'item mr-0 mb-20 w-50 h-150 blue-color-08',
                        url: 'https://www.youtube.com/embed/SyWBS2y0CSA',
                        type: 'video'
                    }
                ],
                classes: 'row-flex'
            }
        ]
    ];

    const getImportPdf = (item) => {
        return require(`../../assets/tiles/${item.url}`);
    };

    const clickEvent = item => {
        console.log(item);
        setDialogType(() => item.type);
        if(item.type === 'pdf'){
            setPdfFile(() => getImportPdf(item));
            setVideoUrl(() => null);
        }
        if(item.type === 'video'){
            setPdfFile(() => null);
            setVideoUrl(() => `${item.url}?rel=0&enablejsapi=1`);
        }
        setDialogConfig(prevState => {
            return { ...prevState, showDialog: 'block' }
        });
        console.log('function running setimeout', dialogConfig);
    };

    const dismissDialog = () => {
        if(videoTag.current){
            videoTag.current.children[0].src = `${videoTag.current.children[0].src}?autoplay=1&modestbranding=1&showinfo=0`;
        }
    };

    const renderTile = data => {
        return data.map((item, i) => {
            if(item?.rowData) {
                return (
                    <div key={`i-${i}`} className={item.classes}>
                        { item.rowData.map((item, j) => (
                                <div key={`j-${j}`} onClick={() => clickEvent(item)} className={item.classes}>
                                    <span>{item.title}</span>
                                </div>
                            ))
                        }
                    </div>
                )
            } else {
                return (
                    <div key={`i-${i}`} onClick={() => clickEvent(item)} className={item.classes}>
                        <span>{item.title}</span>
                    </div>
                )
            }
        });
    };

    const renderTiles = () => {
        return data.map((item, i) => {
            return <div key={`p-${i}`} className='main-col-wrapper'>
                {renderTile(item)}
            </div>
        });
    };

    return (
        <div className='tiles-wrapper'>
            {renderTiles()}
            {dialogConfig?.showDialog === 'block' &&
                <Dialog {...dialogConfig} dismissDialog={dismissDialog}>
                    {dialogType === 'pdf' && 
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl={pdfFile}
                            />
                        </Worker>
                    }
                    {dialogType === 'video' &&
                        <div style={{display: 'flex', justifyContent: 'center'}} ref={videoTag}>
                            <iframe src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={{aspectRatio: '16 / 7', width: '100% !important'}}></iframe>
                        </div>
                    }
                </Dialog>
            }
        </div>
    );
}