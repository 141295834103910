import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/home/home';
import Layout from './components/layout/Layout';
import Login from './pages/login/login';
import { useEffect } from 'react';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let loggedIn = sessionStorage?.loggedIn === '0' ? 1 : 0;
    if(loggedIn === 0) {
      navigate('/login');
    } else {
      if(!location.pathname.includes('/login')){
        navigate('/');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}

export default App;
