import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './login.scss';
import pycubeLogo from '../../assets/images/pycube-logo.svg';
import { postResendVerifyEmailRequest, postSendVerifyEmailRequest, postVerifyCodeRequest } from '../../api/pages/login';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [currentScreen, setCurrentScreen] = useState('email');
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    generateCaptcha();
    sessionStorage.clear();
  }, []);

  const sendVerifyEmailRequest = (payload) => {
    postSendVerifyEmailRequest({ ...payload })
      .then(res => {
        console.log('sendVerifyEmail', res);
        if(res.success) {
          setCurrentScreen('ok');
          sessionStorage.setItem('token', res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const sendVerifyCodeRequest = (payload) => {
    postVerifyCodeRequest({ ...payload })
      .then(res => {
        console.log('verifyCode', res);
        if(res.success) {
          navigate('/');
          sessionStorage.setItem('loggedIn', '0');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const resendVerifyEmailRequest = () => {
    postResendVerifyEmailRequest()
      .then(res => {
        console.log('resendVerifyemail', res);
        if(res.success) {
          sessionStorage.setItem('token', res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setGeneratedCaptcha(result);
  };

  const onEmailSubmit = (data) => {
    console.log(data);
    sendVerifyEmailRequest(data);
  };

  const handleOkClick = () => {
    setCurrentScreen('verification');
  };

  const onVerificationSubmit = (data) => {
    console.log(data);
    if (data.captcha.toLowerCase() !== generatedCaptcha.toLowerCase()) {
      alert('Invalid captcha. Please try again.');
      generateCaptcha();
      return;
    } else {
      sendVerifyCodeRequest({code: data.code});
    }
    // verification logic here
    console.log('Verification successful');
  };

  const renderEmailInput = () => (
    <div className="login-page">
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <div className="input-container">
          <input
            {...register('email', { 
              required: 'Email is required', 
              pattern: {
                value: /@pycube\.com$/,
                message: 'Must be a valid email'
              }
            })}
            placeholder="Enter your Pycube email here"
            className="email-input"
          />
          <button type="submit">GO</button>
        </div>
        {errors.email && <span className="error">{errors.email.message}</span>}
      </form>
    </div>
  );

  const renderOkPage = () => (
    <div className="ok-page">
      <p>1. We have sent you a code to your email.</p>
      <p>2. Check the code sent and enter it in the field below.</p>
      <button onClick={handleOkClick}>OK</button>
    </div>
  );

  const renderVerificationPage = () => (
    <div className="verification-page">
      <h2>Pycube Demos</h2>
      <form onSubmit={handleSubmit(onVerificationSubmit)}>
        <input
          {...register('code', { required: 'Verification code is required' })}
          className='email'
          placeholder="Enter the code from your email"
        />
        {errors.code && <span className="error">{errors.code.message}</span>}
        <div className="captcha-container">
          <div className='cptcha'>
            <span>{generatedCaptcha}</span>
            <button type="button" onClick={generateCaptcha} className="refresh-captcha">↻</button>
          </div>
          <input
            {...register('captcha', { required: 'Captcha is required' })}
            placeholder="Enter your captcha"
          />
        </div>
        {errors.captcha && <span className="error">{errors.captcha.message}</span>}
        <button type="submit">GET STARTED</button>
      </form>
      <p>Did not receive a code? <span className="resend-link" onClick={() => resendVerifyEmailRequest()}>RESEND</span></p>
    </div>
  );

  return (
    <>
      <div className="login-container">
        {currentScreen === 'email' && renderEmailInput()}
        {currentScreen === 'ok' && renderOkPage()}
        {currentScreen === 'verification' && renderVerificationPage()}
      </div>
      <img src={pycubeLogo} alt="Pycube Logo" className="pycube-logo" />
      <div className="title">Innovation Starts Here!</div>
    </>
  );
};

export default Login;